import React from 'react'

const Footer = () => {
  return (
    <div className='   text-white bg-black  text-center font-[900] pt-5 pb-6   h-content'>
        <p>Copyright © 2023. All rights are reserved</p>
    </div>
  )
}

export default Footer